import React, {useState, useEffect} from 'react';
import './MazelogSearchComponent.css';
import {
  Button,
  Dropdown,
  DropdownItem,
  Grid,
  Col,
  Title,
  TextInput,
  DateRangePicker,
  Card,
  Divider,
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
  TableBody,
  TableHead,
  DateRangePickerValue
  } from '@tremor/react';
import { ShortOrder } from '../../interfaces/OrderInterfaces';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Drivers } from '../../interfaces/UserInterfaces';
import { ptBR } from "date-fns/locale";
import { Link } from "react-router-dom";
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';

export default function MazelogSearchComponent(
  {drivers, selectedOrders, setSelectedOrders}:{drivers:Drivers, selectedOrders:string[], setSelectedOrders:Function}
) {
  const limit = 100;
  const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;
  const [cookies] = useCookies(['mazelog-user']);
  const orgData = cookies["mazelog-user"]?.organization_data;
  const token = cookies['mazelog-user']?.token_data?.access_token;
  const oauth = "Bearer " + token;

  const now = new Date();

  const [requested, setRequested] = useState<boolean>(false);
  const [searchedOrders, setSearchedOrders] = useState<ShortOrder[]>([]);
  const [selectedNumber, setSelectedNumber] = useState<string>('');
  const [selectedReceipt, setSelectedReceipt] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string>('all');
  const [selectedReceiver, setSelectedReceiver] = useState<string>('');
  const [selectedDriver, setSelectedDriver] = useState<number>();
  const [wrongDriverId, setWrongDriverId] = useState<boolean>(false);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangePickerValue>([
    new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
    now
  ]);
  const [expansions, setExpansions] = useState<number>(0);
  const [fileDownloadLoading, setFileDownloadLoading] = useState<boolean>(false);

  const statusTranslate: { [key: string]: string } = {
    "created": "Criado",
    "transporter_allocated": "Transportador alocado",
    "collected": "Coletado",
    "received": "Recebido",
    "en_route": "Em rota",
    "delivered": "Entregue",
    "cancelled": "Cancelado",
    "handling": "Em tratativa",
    "awaiting_return": "Aguard. devolução",
    "awaiting_transfer": "Aguard. transferência",
    "return_route": "Rota devolução",
    "returned": "Devolvido",
    "collected_not_received": "Coletados Não Receb.",
    "cancelled_after_received": "Canc. após Receb.",
    "returned_area_not_serviced": "Devolvido - Área não atendida",
    "new_driver_allocated": "Novo entregador alocado"
  }

  const expandSearchResults = () => {
    setExpansions(expansions+1);
    setRequested(false);
  }

  const buildOrderCsv = async () : Promise<string> =>  {
    let URL = `${baseURL}/order/query/file?emission_start_date=${selectedDateRange[0]?.toISOString()}&emission_end_date=${selectedDateRange[1]?.toISOString()}`
    if(selectedNumber !== ''){URL += `&order_number=${selectedNumber}`}
    if(selectedReceipt !== ''){URL += `&receipt_number=${selectedReceipt}`}
    if(selectedStatus !== 'all'){URL += `&status=${selectedStatus}`}
    if(selectedReceiver !== ''){URL += `&receiver_document=${selectedReceiver.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\s]/g,"")}`}
    if(selectedDriver){URL += `&courier_id_user=${selectedDriver}`}
    const resp = await axios.get(URL,{headers: {accept: 'application/json', "Authorization": oauth}})
    return resp.data
  }

  const downloadCsv = async () => {
    setFileDownloadLoading(true);
    const csvData = await buildOrderCsv();
    const a = document.createElement("a");
    a.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvData));
    a.setAttribute("download", "download.csv");
    a.click();
    setFileDownloadLoading(false);
  }

  const driverFilterHandler = (email: string) => {
    const id_user = Object.keys(drivers).find((key:any) => drivers[key].email === email);
    if (id_user) {
      setWrongDriverId(false);
      setSelectedDriver(parseInt(id_user));
    } else {
      setSelectedDriver(undefined);
      setWrongDriverId(true);
    }
  }

  const selectOneHandler = (orderNumber: string) => {
      if (selectedOrders.includes(orderNumber)) {
          setSelectedOrders(selectedOrders.filter((value)=>{
              return value !== orderNumber
          }))
      } else {
          const updatedSelectedOrders = selectedOrders.concat(orderNumber);
          setSelectedOrders(updatedSelectedOrders);
      }
  }

  const selectAllHandler = () => {
      if (searchedOrders.length === selectedOrders.length) {
          setSelectedOrders([])
      } else {
          const orderNumbers = searchedOrders.map(({orderNumber}) => orderNumber);
          setSelectedOrders(orderNumbers);
      }
  }

  const convertDate = (date:Date) => {
      let newDate = new Date(date);
      newDate.setHours(newDate.getHours() - 3);
      let newDateStr = newDate.toLocaleString('en-GB').substring(0, 10);
      return newDateStr
  }

  const truncateString = (text:string, maxLength: number):string => {
      if (text.length <= maxLength) {
          return text
      } else {
          return text.substring(0, maxLength) + '...'
      }
  }

    const handleSubmit = () => {
      let URL = `${baseURL}/order/query?emission_start_date=${selectedDateRange[0]?.toISOString()}&emission_end_date=${selectedDateRange[1]?.toISOString()}`
      if(selectedNumber !== ''){URL += `&order_number=${selectedNumber}`}
      if(selectedReceipt !== ''){URL += `&receipt_number=${selectedReceipt}`}
      if(selectedStatus !== 'all'){URL += `&status=${selectedStatus}`}
      if(selectedReceiver !== ''){URL += `&receiver_document=${selectedReceiver.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\s]/g,"")}`}
      if(selectedDriver){URL += `&courier_id_user=${selectedDriver}`}
      if(expansions > 0){URL+= `&expansions=${expansions}`}
      axios.get(URL,{headers: {accept: 'application/json', "Authorization": oauth}})
      .then((res)=>{
        console.log(res);
        setSearchedOrders(res.data.results);
      })
      .catch((err)=>{
        console.log(err);
      })
    }

    useEffect(()=>{
      if (!requested){
        handleSubmit();
        setRequested(true);
      }
    },[requested])

    return (
      <div className='w-full'>
        <Card>
          <Grid numCols={2} className="gap-2">
            <Col numColSpan={1}>
              <Title>ID do pedido</Title>
              <TextInput
                className='max-w-md'
                onChange={(e) => setSelectedNumber(e.target.value)}
              />
            </Col>
            <Col numColSpan={1}>
              <Title>Número da nota</Title>
              <TextInput
                placeholder='0000000000'
                className='max-w-md'
                onChange={(e) => setSelectedReceipt(e.target.value)}
              />
            </Col>
            <Col numColSpan={1}>
            <Title>Status</Title>
            <Dropdown
                  className='max-w-md'
                  defaultValue="all"
                  onValueChange={ (value) => setSelectedStatus(value) }
              >
                  <DropdownItem value="all" text="Todos os status" />
                  <DropdownItem value="created" text="Criado" />
                  <DropdownItem value="transporter_allocated" text="Transportador alocado" />
                  <DropdownItem value="collected" text="Coletado" />
                  <DropdownItem value="received" text="Recebido" />
                  <DropdownItem value="en_route" text="Em rota" />
                  <DropdownItem value="delivered" text="Entregue" />
                  <DropdownItem value="cancelled" text="Cancelado" />
                  <DropdownItem value="handling" text="Em tratativa" />
                  <DropdownItem value="awaiting_return" text="Aguard. devolução" />
                  <DropdownItem value="awaiting_transfer" text="Aguard. transferência" />
                  <DropdownItem value="return_route" text="Rota devolução" />
                  <DropdownItem value="returned" text="Devolvido" />
                  <DropdownItem value="collected_not_received" text="Coletados Não Receb." />
                  <DropdownItem value="cancelled_after_received" text="Canc. após Receb." />
                  <DropdownItem value="returned_area_not_serviced" text="Devolvido - Área não atendida" />
                  <DropdownItem value="new_driver_allocated" text="Novo entregador alocado" />
              </Dropdown>
            </Col>
            <Col numColSpan={1}>
              <Title>CNPJ/CPF destinatário</Title>
              <TextInput placeholder="00000000000000" className='max-w-md' onChange={(e) => setSelectedReceiver(e.target.value)}/>                  
            </Col>
            {['CORP', 'TRANSPORTER'].includes(orgData?.profile) ?
            <Col numColSpan={1}>
              <Title>Email entregador</Title>
              <TextInput className='max-w-md' error={wrongDriverId} onChange={(e) => driverFilterHandler(e.target.value)}/>
            </Col> : ""}
            <Col numColSpan={1}>
              <Title>Data de criação</Title>
              <DateRangePicker
                className="max-w-md"
                value={selectedDateRange}
                onValueChange={setSelectedDateRange}
                locale={ptBR}
                dropdownPlaceholder="Selecionar"
                enableYearPagination={true}
              />
            </Col>
            <Col numColSpan={1} className='DownloadCSVContainer'>
              <Button className='FilterButton' onClick={handleSubmit}>
                Filtrar
              </Button>
              <Button
                className='DownloadCSVButton'
                onClick={downloadCsv}
                loading={fileDownloadLoading}
              >
                Download
              </Button>
              <Link to="/orders">
                <Button className='OpenStickerButton'>
                  Abrir etiquetas
                </Button>
              </Link>
            </Col>
          </Grid>
          <Divider className='h-1'/>
          <div>
            <Table>
              <TableHead>
                  <TableRow>
                      <TableHeaderCell>
                          <input
                              type='checkbox'
                              checked={searchedOrders.length === selectedOrders.length}
                              onChange={selectAllHandler}
                              className='OrderCheckBox'
                          />
                      </TableHeaderCell>
                      <TableHeaderCell className="text-left">ID do pedido</TableHeaderCell>
                      <TableHeaderCell className="text-left">Número da nota</TableHeaderCell>
                      <TableHeaderCell className="text-left">Data de criação</TableHeaderCell>
                      <TableHeaderCell className="text-left">Data de atualização</TableHeaderCell>
                      <TableHeaderCell className="text-left">Status</TableHeaderCell>
                      <TableHeaderCell className="text-left">Destinatário</TableHeaderCell>
                      <TableHeaderCell className="text-left">CEP Destinatário</TableHeaderCell>
                  </TableRow>
              </TableHead>

              <TableBody>
                  { searchedOrders
                      .map((order) => (
                          <TableRow key={ order.orderNumber }>
                              <TableCell className="TableField">
                                  <input
                                      type='checkbox'
                                      checked={selectedOrders.includes(order.orderNumber)}
                                      onChange={() => selectOneHandler(order.orderNumber)}
                                  />
                              </TableCell>
                              <TableCell className="TableField">
                                <Link to={`/order/${order.orderNumber}`}>
                                  <u>{ order.orderNumber }</u>
                                </Link>
                              </TableCell>
                              <TableCell className="TableField">{ order.receiptNumber }</TableCell>
                              <TableCell className="TableField">{ convertDate(order.creationDate) }</TableCell>
                              <TableCell className="TableField">{ convertDate(order.lastStatusDate) }</TableCell>
                              <TableCell className="TableField">{ statusTranslate[order.status] }</TableCell>
                              <TableCell className="TableField">{ truncateString(order.receiver, 25) }</TableCell>
                              <TableCell className="TableField">{ order.receiverZIP }</TableCell>
                              <Link to={`/order/${order.orderNumber}`}>
                                  <Button className='OpenOrderBtn' icon={ArrowUpRightIcon} />
                              </Link>
                          </TableRow>
                      )) }
                </TableBody>
            </Table>
          </div>
          {searchedOrders.length === limit*(expansions+1) ?
          <div>
            <Button onClick={expandSearchResults} className='FilterButton'>
              Carregar mais
            </Button>
          </div> : ""}
        </Card>
      </div>
    );
  }